body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.leaflet-container {
  height: 480px;
  margin: 5px;
}

/* Map marker's CSS */
.solid_icon{
    position:absolute;
}
.solid_icon:before{
    content:'';
    position:absolute;
    width:24px;
    height:24px;
    left:-12px;
    top:-22px;
    border:solid 1px;
    background:currentColor;
    border-radius:10px 10px 10px 0;
    -webkit-transform:rotate(-45deg);
        -ms-transform:rotate(-45deg);
            transform:rotate(-45deg);
    box-sizing:content-box;
}
.solid_icon:after{
    content:'';
    position:absolute;
    left:-5px;
    top:-16px;
    width:12px;
    height:12px;
    background-color:rgba(255, 255, 255, 0.7);
    border-radius:8px;
}
/*マーカーの色のCSS*/
.color_palette{
    background-color:#ea4f4f;
    color:#ea4f4f;
    border-color:#ea4f4f;
}
